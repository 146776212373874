<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage" />
    <a-card :bordered="false" v-else>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan" v-if="this.tabKey === '1'">
              <a-form-item label="入库单号">
                <a-input placeholder="请输入入库单号" v-model.trim="queryParam.purchase_num" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" v-if="this.tabKey === '1'">
              <a-form-item label="入库时间">
                <a-range-picker @change="onDateChange" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" v-if="this.tabKey === '1'">
              <a-form-item label="供应商">
                <a-select
                  showSearch
                  allowClear
                  :filter-option="filterOption"
                  placeholder="选择供应商"
                  v-model="queryParam.supplier_ids"
                  mode="multiple"
                >
                  <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" v-if="this.tabKey === '1' || this.tabKey === '2'">
              <a-form-item label="商品名称">
                <a-input v-model.trim="queryParam['good_name']" placeholder="请输入商品名称" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" v-if="this.tabKey === '1'">
              <a-form-item label="商品规格">
                <a-input v-model.trim="queryParam['good_spec']" placeholder="请输入商品规格" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" v-if="this.tabKey === '1'">
              <a-form-item label="商品编号">
                <a-input v-model.trim="queryParam['good_no']" placeholder="请输入商品编号" />
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan" v-if="this.tabKey === '1'">
              <a-form-item label="在途商品">
                <a-select
                  style="width: 100%"
                  v-model="queryParam['unarrive']"
                  allowClear
                  placeholder="请选择在途商品"
                >
                  <a-select-option :value="0">无</a-select-option>
                  <a-select-option :value="1">有</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col v-bind="GLOBAL.queryColSpan">
              <span class="table-page-search-submitButtons">
                <template v-if="this.tabKey === '1'">
                  <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
                </template>
                <template v-if="this.tabKey === '2'">
                  <a-button type="query" icon="search" @click="$refs.table2.refresh(true)">查询</a-button>
                </template>
                <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
                <a-popover
                  v-model="popVisible"
                  title="选择月份"
                  trigger="click">
                  <a slot="content">
                    <p><a-month-picker v-model="exportDate" style="width: 200px;" format="YYYY/MM" placeholder="请选择月份"></a-month-picker></p>
                    <p style="text-align: right"><a-button type="primary" @click="handleExport">确定</a-button></p>
                  </a>
                  <a-button type="primary">库存盘点单</a-button>
                </a-popover>
                <a-popover
                  v-model="popVisible2"
                  title="选择月份"
                  trigger="click">
                  <a slot="content">
                    <p><a-month-picker v-model="exportDate2" style="width: 200px;" format="YYYY/MM" placeholder="请选择月份"></a-month-picker></p>
                    <p style="text-align: right"><a-button type="primary" @click="handleExportInAndOut">确定</a-button></p>
                  </a>
                  <a-button type="primary">已开票盘点单</a-button>
                </a-popover>
                <a-button type="primary" @click="handleExportCalendarYearInAndOut">未开票盘点单</a-button>
                <a-popover
                  v-model="popVisible6"
                  title="选择月份"
                  trigger="click">
                  <a slot="content">
                    <p><a-month-picker v-model="exportDate6" style="width: 200px;" format="YYYY/MM" placeholder="请选择月份"></a-month-picker></p>
                    <p style="text-align: right"><a-button type="primary" @click="exportInventoryCountSumSheet">确定</a-button></p>
                  </a>
                  <a-button type="primary">库存盘点表（汇总表）</a-button>
                </a-popover>
                <a-button type="primary" @click="handleExportByDate">出入库盘点单</a-button>
                <a-popover
                  v-model="popVisible7"
                  title="选择月份"
                  trigger="click">
                  <a slot="content">
                    <p><a-date-picker
                      v-model="exportYearDate7"
                      mode="year"
                      style="width: 200px;"
                      format="YYYY"
                      placeholder="请选择年份"
                      :open="yearShowOne7"
                      @openChange="openChangeOne2"
                      @panelChange="panelChangeOne2">
                    </a-date-picker></p>
                    <p style="text-align: right"><a-button type="primary" @click="exportYearFinanceSheet">确定</a-button></p>
                  </a>
                  <a-button type="primary">年度盘点表（汇总表）</a-button>
                </a-popover>
                <a-popover
                  v-model="popVisible8"
                  title="选择月份"
                  trigger="click">
                  <a slot="content">
                    <p><a-month-picker v-model="exportDate8" style="width: 200px;" format="YYYY/MM" placeholder="请选择月份"></a-month-picker></p>
                    <p style="text-align: right"><a-button type="primary" @click="handleSaveStockStatistic">确定</a-button></p>
                  </a>
                  <a-button type="primary">生成库存盘点</a-button>
                </a-popover>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <a-tabs :default-active-key="tabKey" @change="tabChange">
        <a-tab-pane key="1" tab="库存明细">
          <s-table
            ref="table"
            size="small"
            :alert="{
              show: true,
              msg: showTotal
            }"
            :rowKey="
              function(record) {
                return record.id + '' + record.good_id
              }
            "
            :columns="columns"
            :data="loadData"
          >
            <span slot="serial" slot-scope="text, record, index">
              {{ index + 1 }}
            </span>
            <span slot="action" slot-scope="text, record, index">
              <template>
                <a @click="handleDetail(record, index)">查看详情</a>
                <a-divider type="vertical" />
                <a @click="handleArriveStock(record, index)">到库设置</a>
              </template>
            </span>
          </s-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="库存汇总">
          <s-table
            ref="table2"
            size="small"
            :alert="{
              show: true,
              msg: showTotal2
            }"
            :rowKey="
              function(record) {
                return record.id + '' + record.good_id
              }
            "
            :columns="columns2"
            :data="loadData2"
          >
            <span slot="serial" slot-scope="text, record, index">
              {{ index + 1 }}
            </span>
            <span slot="action" slot-scope="text, record, index">
              <template>
                <a @click="handleDetail2(record, index)">查看详情</a>
              </template>
            </span>
          </s-table>
        </a-tab-pane>
      </a-tabs>

      <a-modal
        :visible="visible"
        :confirm-loading="confirmLoading"
        title="新增库存"
        :width="1100"
        :maskClosable="false"
        @cancel="visible = false"
        @ok="handleSubmit"
      >
        <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
          <a-row :gutter="20">
            <a-col :span="6">
              <a-form-model-item label="入库单">
                <a-select
                  showSearch
                  allowClear
                  placeholder="请选择入库单"
                  style="width: 100%"
                  v-model="form.purchase_id"
                  @change="changePurchase"
                >
                  <a-select-option v-for="op in purchaseGoodList" :key="op.purchase.id">{{
                    op.purchase.purchase_num
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="供应商" prop="supplier_id">
                <a-input v-model="supplierInfo" disabled/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="仓库" prop="warehouse_id">
                <a-select placeholder="选择仓库" ref="warehouse" showSearch v-model="form.warehouse_id">
                  <a-select-option v-for="d in warehouseOps" :key="d.value">{{ d.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <edit-table
              :columns="goodsColumns"
              :sourceData="goodsData"
              :scroll="true"
              :disabled="goodsDisabled"
              @getTableDate="getGoodsData"
            ></edit-table>
          </a-row>
        </a-form-model>
      </a-modal>
      <a-modal
        :visible="arriveVisible"
        :confirm-loading="arriveLoading"
        title="到库数量设置"
        :width="800"
        :maskClosable="false"
        @cancel="arriveVisible = false"
        @ok="handleArrivesubmit"
      >
        <a-form-model layout="vertical" :model="arriveForm" :rules="rules" ref="arriveForm">
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-model-item label="入库单">
                <p>{{ arriveForm.purchase_num }}</p>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="商品名称">
                <p>{{ arriveForm.good_name }}</p>
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="规格" >
                <p>{{ arriveForm.good_spec }}</p>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="入库数量">
                <p>{{ arriveForm.total_num }}</p>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="到库数量">
                <a-input v-model="arriveForm.arrive_num"/>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </a-card>
  </keep-alive>
</template>
<script>
import { STable, EditTable } from '@/components'
import {
  getStockPage,
  getStockSummaryPage,
  exportStockDetail,
  exportInAndOut,
  exportCalendarYearInAndOutSheet,
  exportStockByDate,
  getWarehouseList,
  getPurchaseGoodList,
  getGoodInfoList,
  addGoodStock,
  getWmsGoodStockIdByGoodId,
  exportGoodStockSheet,
  handleSumExport,
  handleExportInAndOutSum,
  handleExportCalendarYearInAndOutSum,
  exportInventoryCountSumSheet,
  modifyStockArrive,
  exportFinnaceInOutSheet,
  saveStockStatistic
} from '@/api/wms/sale'
import { getFirstLetter } from '@/utils/util'
import { downloadExcel, getSupplier } from '@/api/common'
import moment from 'moment'
export default {
  components: {
    STable,
    EditTable
  },
  data() {
    return {
      queryParam: {},
      showTotal: '',
      showTotal2: '',
      form: {
        purchase_id: undefined,
        purchase_num: '',
        supplier_id: undefined,
        warehouse_id: undefined
      },
      arriveForm: {
        id: null,
        arrive_num: null,
        purchase_num: null,
        good_name: null,
        good_spec: null,
        total_num: null
      },
      goodsData: [],
      visible: false,
      confirmLoading: false,
      arriveVisible: false,
      arriveLoading: false,
      warehouseOps: [],
      purchaseGoodList: [],
      supplierOps: [], // 供应商
      rules: {
        warehouse_id: [{ required: true, message: '请选择仓库', trigger: 'change' }]
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '入库单号',
          dataIndex: 'purchase_num'
        },
        {
          title: '入库时间',
          dataIndex: 'purchase_date'
        },
        {
          title: `供应商`,
          dataIndex: `supplier_name`
        },
        {
          title: '仓库',
          dataIndex: 'warehouse'
        },
        {
          title: '商品编号',
          dataIndex: 'good_no'
        },
        {
          title: '商品名称',
          dataIndex: 'good_name'
        },
        {
          title: '单位',
          dataIndex: 'good_unit'
        },
        {
          title: '规格',
          dataIndex: 'good_spec'
        },
        {
          title: '入库数量',
          dataIndex: 'total_num'
        },
        {
          title: '到库数量',
          dataIndex: 'arrive_num'
        },
        {
          title: '剩余数量',
          dataIndex: 'surplus_num'
        },
        {
          title: '未发货数量',
          dataIndex: 'unsend_num'
        },
        {
          title: '单价',
          dataIndex: 'unit_price'
        },
        {
          title: '总价',
          dataIndex: 'total'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      columns2: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '商品编号',
          dataIndex: 'good_no',
          width: 120
        },
        {
          title: '商品名称',
          dataIndex: 'good_name'
        },
        {
          title: `单位`,
          dataIndex: `good_unit`
        },
        {
          title: `规格`,
          dataIndex: `good_spec`
        },
        {
          title: `库存数量`,
          dataIndex: `total_surplus_num`
        },
        {
          title: `总价`,
          dataIndex: `total_price`
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      goodNoOps: [],
      goodInfoList: [],
      goodsColumns: [
        {
          title: `货物编码`,
          dataIndex: `good_no`,
          extra: {
            type: 'text'
          }
        },
        {
          title: '货物名称',
          dataIndex: 'good_name',
          extra: {
            type: 'text'
          }
        },
        {
          title: '货物单位',
          dataIndex: 'good_unit',
          extra: {
            type: 'text'
          }
        },
        {
          title: '货物规格',
          dataIndex: 'good_spec',
          extra: {
            type: 'text'
          }
        },
        {
          title: '生产日期',
          dataIndex: 'product_date',
          extra: {
            type: 'text'
          }
        },
        {
          title: '保质期',
          dataIndex: 'quality_days',
          extra: {
            type: 'text'
          }
        },
        {
          title: '总数量',
          dataIndex: 'num',
          extra: {
            type: 'text'
          }
        },
        {
          title: '可入库数量',
          dataIndex: 'un_store_num',
          extra: {
            type: 'number'
          }
        },
        {
          title: '到库数量',
          dataIndex: 'arrive_num',
          extra: {
            type: 'number'
          }
        }
      ],
      selectedRowKeys: [],
      supplierItems: [],
      loadData: parameter => {
        return getStockPage(Object.assign(parameter, this.queryParam)).then(res => {
          this.showTotal =
            '数量合计：' +
            ((res.total_row && res.total_row.num) || 0) +
            '、总价合计：' +
            ((res.total_row && res.total_row.total) || 0)
          return res
        })
      },
      loadData2: parameter => {
        return getStockSummaryPage(Object.assign(parameter, this.queryParam)).then(res => {
          this.showTotal2 =
            '数量合计：' +
            ((res.total_row && res.total_row.num) || 0) +
            '、总价合计：' +
            ((res.total_row && res.total_row.total) || 0)
          return res
        })
      },
      tabKey: `1`,
      goodsDisabled: false,
      supplierInfo: '',
      fetching: false,
      popVisible: false,
      exportDate: null,
      popVisible2: false,
      exportDate2: null,
      popVisible3: false,
      exportDate3: null,
      popVisible4: false,
      exportDate4: null,
      popVisible5: false,
      exportDate5: null,
      popVisible6: false,
      exportDate6: null,
      popVisible7: false,
      exportYearDate7: null,
      yearShowOne7: false,
      popVisible8: false,
      exportDate8: null
    }
  },
  created() {
    getWarehouseList().then(v => {
      this.warehouseOps = v
    })
    getGoodInfoList().then(res => {
      this.goodInfoList = res
      this.goodInfoOps = res.map(e => {
        return {
          name: `${e.good_name}/${e.good_unit}/${e.good_spec}`,
          value: e.id
        }
      });
      for (let i = 0; i < res.length; i++) {
        if (res[i].good_no && res[i].good_no !== '') {
          this.goodNoOps.push({
            name: res[i].good_no,
            value: res[i].id
          });
        }
      }
    })
    getSupplier({ department: 8 }).then(res => {
      this.supplierOps = res
    })
  },
  methods: {
    openChangeOne2(status) {
      if (status) {
        this.yearShowOne7 = true;
      }
    },
    panelChangeOne2(value) {
      this.exportYearDate7 = value;
      this.yearShowOne7 = false;
    },
    exportYearFinanceSheet() {
      if (this.exportYearDate7) {
        this.queryParam['year'] = this.exportYearDate7.year();
        exportFinnaceInOutSheet(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible7 = false;
        });
      } else {
        this.$message.warning('请选择导出年份');
      }
    },
    // 库存盘点表（汇总表）
    exportInventoryCountSumSheet() {
      if (this.exportDate6) {
        this.queryParam['year'] = this.exportDate6.year();
        this.queryParam['month'] = this.exportDate6.month() + 1;
        exportInventoryCountSumSheet(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible6 = false;
        });
      } else {
        this.$message.warning('请选择导出月份');
      }
    },
    handleSaveStockStatistic() {
      if (this.exportDate8) {
        this.queryParam['year'] = this.exportDate8.year();
        this.queryParam['month'] = this.exportDate8.month() + 1;
        saveStockStatistic(this.queryParam).then(res => {
          if (res) {
            this.$message.success('记录生成成功');
          }
        }).finally(_ => {
          this.popVisible8 = false;
        });
      } else {
        this.$message.warning('请选择导出月份');
      }
    },
    // 库存盘点单（汇总表）
    handleSumExport() {
      if (this.exportDate4) {
        this.queryParam['year'] = this.exportDate4.year();
        this.queryParam['month'] = this.exportDate4.month() + 1;
        handleSumExport(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible4 = false;
        })
      } else {
        this.$message.warning('请选择导出月份');
      }
    },
    // 已开票盘点单（汇总表）
    handleExportInAndOutSum() {
      if (this.exportDate5) {
        this.queryParam['year'] = this.exportDate5.year();
        this.queryParam['month'] = this.exportDate5.month() + 1;
        handleExportInAndOutSum(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible5 = false;
        })
      } else {
        this.$message.warning('请选择导出月份');
      }
    },
    // 未开票盘点单（汇总表）
    handleExportCalendarYearInAndOutSum() {
      handleExportCalendarYearInAndOutSum(this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data;
            let fileName = ``;
            const contentDisposition = downRes.headers['content-disposition'];
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split(`=`)[1]));
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` })
            )
            const link = document.createElement(`a`);
            link.style.display = 'none';
            link.href = url;
            link.setAttribute(`download`, fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
        }
      });
    },
    // 库存表信息导出
    exportGoodStockSheet() {
      if (this.exportDate3) {
        const param = {
          year: this.exportDate3.year(),
          month: this.exportDate3.month() + 1
        }
        exportGoodStockSheet(param).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data;
              let fileName = ``;
              const contentDisposition = downRes.headers['content-disposition'];
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split(`=`)[1]));
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` })
              )
              const link = document.createElement(`a`);
              link.style.display = 'none';
              link.href = url;
              link.setAttribute(`download`, fileName);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
          }
        });
      } else {
        this.$message.warning('请选择导出月份');
      }
    },
    // 历年出入库盘点单
    handleExportCalendarYearInAndOut() {
      exportCalendarYearInAndOutSheet(this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data;
            let fileName = ``;
            const contentDisposition = downRes.headers['content-disposition'];
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split(`=`)[1]));
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` })
            )
            const link = document.createElement(`a`);
            link.style.display = 'none';
            link.href = url;
            link.setAttribute(`download`, fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
        }
      });
    },
    // 出入库盘点单
    handleExportByDate() {
      exportStockByDate(this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data;
            let fileName = ``;
            const contentDisposition = downRes.headers['content-disposition'];
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split(`=`)[1]));
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` })
            )
            const link = document.createElement(`a`);
            link.style.display = 'none';
            link.href = url;
            link.setAttribute(`download`, fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
        }
      });
    },
    // 当月出入库盘点单
    handleExportInAndOut() {
      if (this.exportDate2) {
        this.queryParam['year'] = this.exportDate2.year();
        this.queryParam['month'] = this.exportDate2.month() + 1;
        exportInAndOut(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data;
              let fileName = ``;
              const contentDisposition = downRes.headers['content-disposition'];
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split(`=`)[1]));
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet` })
              )
              const link = document.createElement(`a`);
              link.style.display = 'none';
              link.href = url;
              link.setAttribute(`download`, fileName);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
          }
        }).finally(_ => {
          this.popVisible2 = false;
        });
      } else {
        this.$message.warning('请选择导出月份');
      }
    },
    // key是字符串类型
    tabChange(key) {
      if (key === `1`) {
        // 库存明细
        this.tabKey = '1';
        this.$refs.table.refresh(true);
      } else {
        // 库存汇总
        this.tabKey = '2';
        this.$refs.table2.refresh(true);
      }
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['start_date'] = startDate
        this.queryParam['end_date'] = endDate
      } else {
        this.queryParam['start_date'] = null
        this.queryParam['end_date'] = null
      }
    },
    changePurchase(value) {
      if (value) {
        let goodInfo = null
        for (const item of this.purchaseGoodList) {
          if (item.purchase.id === value) {
            goodInfo = item
          }
        }
        if (goodInfo !== null) {
          this.form.purchase_id = goodInfo.purchase.id
          this.form.purchase_num = goodInfo.purchase.purchase_num
          this.form.supplier_id = goodInfo.purchase.supplier_id
          this.form.warehouse_id = goodInfo.purchase.warehouse_id
          this.goodsData = goodInfo.good_in_list
          this.supplierInfo = goodInfo.purchase.supplier_name
        }
        this.goodsDisabled = true;
      } else {
        this.form = {
          purchase_id: undefined,
          purchase_num: '',
          supplier_id: undefined,
          warehouse_id: undefined
        }
        this.goodsData = [];
        this.goodsDisabled = false;
      }
    },
    editDisable() {
      return true
    },
    handleDetail(record) {
      record.id = [record.id];
      this.$router.push({
        name: 'StockDetail',
        params: record
      })
    },
    handleArriveStock(record) {
      this.arriveForm.id = record.id
      this.arriveForm.arrive_num = record.arrive_num
      this.arriveForm.purchase_num = record.purchase_num
      this.arriveForm.good_name = record.good_name
      this.arriveForm.good_spec = record.good_spec
      this.arriveForm.total_num = record.total_num
      this.arriveVisible = true
    },
    handleArrivesubmit() {
      this.arriveLoading = true
      if (this.arriveForm.arrive_num > this.arriveForm.total_num) {
        this.$message.warning('到库数量必须小于入库数量')
        this.arriveLoading = false
        return
      }
      modifyStockArrive(this.arriveForm).then(res => {
        if (res) {
              this.$notification['success']({
                message: '提示',
                description: '修改成功.'
              })
              this.arriveVisible = false
              this.$refs.table.refresh(true)
            }
          }).finally(() => {
            this.arriveLoading = false
          })
    },
    handleDetail2(record) {
      getWmsGoodStockIdByGoodId({
        goodId: record.good_id
      }).then(res => {
        record.id = res;
        this.$router.push({
          name: 'StockDetail',
          params: record
        })
      });
    },
    getGoodsData(data) {
      this.goodsData = data
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    handleExport() {
      if (this.exportDate) {
        this.queryParam['year'] = this.exportDate.year();
        this.queryParam['month'] = this.exportDate.month() + 1;
        exportStockDetail(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible = false;
        })
      } else {
        this.$message.warning('请选择导出月份');
      }
    },
    handleAdd() {
      getPurchaseGoodList().then(v => {
        this.purchaseGoodList = v
      })
      this.form = {
        purchase_id: undefined,
        purchase_num: '',
        supplier_id: undefined,
        warehouse_id: undefined
      }
      this.supplierInfo = ''
      this.goodsData = []
      this.visible = true
    },
    handleSubmit() {
      this.confirmLoading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          var checkNum = true
          this.goodsData.forEach(v => {
            v.product_date = v.product_date ? moment(v.product_date).valueOf() : v.product_date;
            if (v.arrive_num > v.un_store_num) {
              checkNum = false
            }
          });
          if (!checkNum) {
            this.$message.warning('到库数量必须小于可入库数量')
            this.confirmLoading = false
            return
          }
          addGoodStock({
            ...this.form,
            good_in_list: this.goodsData
          }).then(res => {
            if (res) {
              this.$notification['success']({
                message: '提示',
                description: '创建成功.'
              })
              this.visible = false
              this.$refs.table.refresh(true)
            }
          }).finally(() => {
            this.confirmLoading = false
          })
        }
      })
    }
  }
}
</script>
